<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect
                  id="category-name"
                  v-model="categorySopChoose"
                  v-validate="'required'"
                  label="name"
                  name="kategori"
                  track-by="name"
                  placeholder="Pilih kategori"
                  open-direction="bottom"
                  :options="dataCategorySops"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectCategory"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('kategori')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kategori') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Judul
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="'required'"
                class="uk-input"
                type="text"
                name="judul"
                placeholder="Masukan judul"
                :class="{'uk-form-danger': errors.has('judul')}"
              >
              <div
                v-show="errors.has('judul')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('judul') }}
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="uk-width-1-1 uk-margin-top">
          <div class="uk-flex">
            <div class="uk-width">
              <vue-ckeditor
                v-model="formData.content"
                type="classic"
                placeholder="Tulis deskripsi"
                :editors="editors"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import VueCkeditor from 'vue-ckeditor5'

export default {
  components: {
    'vue-ckeditor': VueCkeditor.component
  },
  props: {
    dataSop: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      images: PREFIX_IMAGE,
      editors: {
        classic: ClassicEditor
      },
      formData: {},
      categorySopChoose: {},
      dataCategorySops: []
    }
  },
  computed: {
    ...mapGetters({
      categorySops: 'categorySop/categorySops'
    })
  },
  watch: {
    async dataSop() {
      if (this.dataSop) {
        this.formData = {
          id: this.dataSop.id || '',
          sop_category_id: this.dataSop.sop_category_id || '',
          name: this.dataSop.name || '-',
          content: this.dataSop.content || '-'
        }
        this.categorySopChoose = { id: this.dataSop.sop_category_id, name: this.dataSop.sop_category }
  
        await this.getCategorySop({is_all: true})
        this.dataCategorySops = this.categorySops ? this.categorySops : []
      }
    }
  },
  methods: {
    ...mapActions({
      getCategorySop: 'categorySop/getCategorySop'
    }),
    ...mapMutations({
      setModalEdit: 'detailSop/SET_MODAL_EDIT'
    }),
    onSelectCategory(val) {
      this.formData.sop_category_id = val.id
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setModalEdit(this.formData)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>
